<template>
    <data value="">
        <!-- <ChristmasHomeBannerVideoChristmas :isMobile="isMobile" /> -->
        <ChristmasSpringHomeBanner :isMobile="isMobile" />
        <!-- <div class="mb-4"></div>
        <div class="mb-4"></div>
        <HomeBlackFriday /> -->

        <!-- <div class="padding_block"> </div>
        <ValentinesHomePage />
        <div class="padding_block"></div> -->
    
        <div class="mb-5"></div>
        <HomeFreeProductOffer  />
        <div class="mb-4"></div>

       <BlackFridayShopByCategoryBF />
       <!--  -->
       <!-- <div class="padding_block"></div> -->

        <!-- <ChristmasThroughGifting /> -->

       <div class="padding_block"></div>
       
       
            <ChristmasAllProductBanner />
  
        <div class="padding_block"></div>
       
            <ChristmasHighJewelryBanner />
   

        <div class="padding_block"></div>
        <HomeTennisBraceletBanner />
        <div class="padding_block"></div>

        <div class="container">
            <CommonShapeSlider />
        </div>
        <!-- <section class="shape_block_mod" >
        </section> -->
        <div class="padding_block"></div>
        <section>
            <!-- full_width_mod  -->
            <div class="container full_width" >
                <BlackFridayNewArrivals />
            </div> 
        </section>

        <!-- <div class="container" >
            <div class="padding_block"></div>
            <BlackFridayCreateYourIndiaBF />
        </div> -->

      
        <div class="padding_block"></div>
        <div class="container full_width" >
                <HomeShopByStyle  />
        </div> 
        <div class="padding_block"></div>
        <div class="container full_width" >
            <!-- <div class="padding_block"></div> -->
            <BlackFridayShopByPriceBF page="'index'"  />
        </div>
        <div class="padding_block"></div>


        <!-- <div class="container full_width" >
            <div class="padding_block"></div>
                <BlackFridayInstockBannerBF />
        </div> -->

        <div class="container">
            <HomePrBlogs />
        </div>

        <div class="padding_block_mod"></div>
        <div class="container full_width">
            <HomeTestimonials />
        </div>
        <div class="padding_block_mod"></div>
        <div class="container">
            <div class="evertree_block" >
                <EvertreeBanner />
            </div>
        </div>

        <div class="container">
                <HomeTrustpilotReview  />
            <div class="padding_block" ></div>
        </div>
       
        <HomeBlog :type="'homepage'" />
    </data>
</template>

<script setup>
import { ChristmasHighJewelryBanner, ChristmasSpringHomeBanner } from '#components';

    const {isMobile} = useDevice();

</script>

<style scoped>

.container{
    max-width: 1470px;
    width: 100%;
}
.shape_block{
    background: #faf9f7;
    padding: 3% 0;
}
.padding_block{
    padding-top: 2%;
    padding-bottom: 2%;
}
.padding_block_mod {
    padding: 1% 0;
}
.evertree_block {
    margin: 35px 0;
}
.shape_block_mod{
    /* padding: 3% 0; */
    /* background: url(@/assets/shapes/background.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center; */
}

@media(max-width: 1470px){
    .container{
        width: 80%;
    }
   
}
@media(max-width: 1250px){
    .container{
        width: 90%;
    }
}
@media(max-width: 992px){
    
    
    .shape_block{
        padding: 3%;
    }
}
@media(max-width:512px){
   
    .shape_block_mod {
        padding: 5% 0 !important;
    }

    .shape_block_mod .container {
        padding: 0;
    }

    .shape_block_mod .shape_block {
        padding: 0;
    }
}
@media(max-width:767px){
    .padding_block{
        padding-top: 5%;
        padding-bottom: 5%;
    }
    .container {
        width: 100%;
    }
    .shape_block {
        padding:0;
    }
    .evertree_block {
        margin: 15px 0;
    }

    .full_width.container{
        padding: 0 ;
    }
}



/* @media(min-width:1250px){
    .full_width_mod.container{
        padding-left: 20%;
        max-width: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
    }
    
    .full_width_mod.container{
        padding-left: 20%;    
    }
} */
</style>