<template>
    <data value="">
        <HomeIndexIndia v-if="$country.is_india"/>
        <HomeIndexUs v-else/>
    </data>
</template>

<script setup>

    useFetchMeta( useNuxtApp(), useRuntimeConfig() );
    const { $country } = useNuxtApp()

    onMounted( () => {
        const runtimeConfig = useRuntimeConfig();

    } )


</script>

<style scoped>

.container{
    max-width: 1470px;
    width: 100%;
}
.shape_block{
    background: #faf9f7;
    padding: 3% 0;
}
.padding_block{
    padding-top: 2%;
    padding-bottom: 2%;
}
.evertree_block {
    margin: 35px 0;
}
.shape_block_mod{
    padding: 3% 0;
    background: url(../assets/shapes/background.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}
@media(max-width: 1470px){
    .container{
        width: 80%;
    }
   
}
@media(max-width: 1250px){
    .container{
        width: 90%;
    }
}
@media(max-width: 992px){
    
    .padding_block {
        padding-top: 1%;
        padding-bottom: 1%;
    }
    .shape_block{
        padding: 3%;
    }
}
@media(max-width:512px){
   
    .shape_block_mod {
        padding: 5% 0 !important;
    }

    .shape_block_mod .container {
        padding: 0;
    }

    .shape_block_mod .shape_block {
        padding: 0;
    }
}
@media(max-width:767px){
    .container {
        width: 100%;
      
    }
    .shape_block {
        padding:0;
    }
    .evertree_block {
        margin: 15px 0;
    }
}
</style>