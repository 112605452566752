<template>
	<section class="spring_video_box">
		<div class="spring_left">
			<div class="spring_text">
				<p class="mb-0">This Spring, It’s</p>
				<div class="sun_img">
					<img src="~/assets/spring/sunkissed.png" width="726" height="321" alt="sunkissed everything">
				</div>
				<h3 class="mb-4">flat 40% Off* Engagement Rings & up to 55% Off* Fine Jewelry</h3>
				<div class="ctas">
					<router-link :to="localePath({ name:'cyo-setting-list-ring'})">Create Your Ring</router-link>
					<router-link :to="localePath({ name:'shop-all', query: { 'RefreshKey': 'all' } })">Shop Fine Jewelry</router-link>
				</div>
			</div>
		</div>
		<div class="spring_right">
			<video @contextmenu.prevent="" controlsList="nodownload" class="videodiv" loop muted autoplay playsinline width="1384" height="960">
				<source src="https://assets.friendlydiamonds.com/fdl/homepagebanner/spring_banner.mp4?252222" type="video/mp4">
			</video>
		</div>
		<p class="terms-and-condition" @click="openBFModal">
			<span >*T&C &nbsp;APPLIED</span>
		</p>

		<CommonCustomModal v-model="flagShowModal" :size="'sm'" :no_padd="false">
			<ModalTermsAndConditionsBF />
        </CommonCustomModal>
	</section>
</template>

<script setup>

	const localePath = useLocalePath()
	const props = defineProps(['isMobile'])

	const flagShowModal = ref(false);
	const openBFModal = () => {
        flagShowModal.value = true;
    };
</script>

<style scoped>
.spring_video_box{
	position: relative;
}
.terms-and-condition{
	font-size: 10px;
	position: absolute;
	bottom: 10px;
	right: 15px;
	text-transform: uppercase;
	margin: 0;
	cursor: pointer;
	z-index: 2;
	color: #ffffff;
	text-decoration: underline;
}
video{
	max-width: 100%;
	height: auto;
	display: block;
}
section.spring_video_box {
    display: flex;
}

.spring_left {
    width: 50%;
	background: #fff5de;
	display: flex;
	align-items: center;
	justify-content: center;
}
.sun_img {
    padding: 3% 25%;
}
.spring_text p {
    text-align: center;
    color: #996600;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.spring_text h3 {
    text-align: center;
    color: #996600;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.spring_text h3 span {
    font-size: 28px;
}

.spring_text {
    text-align: center;
}

.ctas {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ctas a {
    letter-spacing: 1px;
    border: 1.5px solid #996600;
    border-radius: 4px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #996600;
    text-transform: uppercase;
    padding: 0 40px;
    margin: 0 10px;
	font-size: 14px;
	font-weight: 600;
}
.spring_right {
    width: 50%;
}

@media(max-width: 1300px){
	.spring_text h3 span {
		font-size: 24px;
	}
}

@media(max-width: 1100px){
	
	.spring_text {
		padding: 2%;
	}
	.ctas a{
		padding: 0 15px;
	}
}
@media(max-width: 992px){
	section.spring_video_box {
		flex-wrap: wrap;
		flex-direction: column-reverse;
	}
	p.terms-and-condition {
		color: #606060 !important;
	}
	.spring_left {
		width: 100%;
		padding: 10% 3%;
	}

	.spring_right {
		width: 100%;
	}
	.spring_text h3{
		font-size: 14px;
	}
	.spring_text p{
		font-size: 14px;
	}
}

@media(max-width: 767px){
	.ctas {
		flex-wrap: wrap;
	}
	.ctas a:first-child{
		margin-bottom: 15px;
	}
	.ctas a {
		width: 70%;
	}
	.spring_text h3 span{
		font-size: 20px;
	}
	.sun_img{
		padding: 5% 10%;
	}
}
</style>