<template>
    <section class="shop_category">
      
            <div class="pt-4">
                <div>
                    <h4 v-if="page === 'cart'" class="mb-4 text-center font-active">Continue Shopping</h4>
                    <h4 v-else class="mb-4 text-center font-active">Shop By Category</h4>
                </div>
                <div v-show="skeleton" class="step_skeleton mt-3">
                    <div class="placeholder skeleton"></div>
                    <div class="placeholder skeleton"></div>
                    <div class="placeholder skeleton"></div>
                    <div class="placeholder skeleton"></div>
                    <div class="placeholder skeleton"></div>
                  </div>
               
                    <!-- :autoplay="{
                        delay: 2500,
                        autoplay:{
                            delay: 1500,
                            disableOnInteraction: true,
                          }
                        disableOnInteraction: false,
                        :modules="[SwiperAutoplay]"
                      }" -->
                    <div v-show="!skeleton">
                        <swiper ref="c1"
                        :loop="true"
                        :loopAdditionalSlides="0"
                        :breakpoints="{
                            365: {
                                slidesPerView: 1.6,
                                slidesPerGroup: 1,
                                centeredSlides : false,
                            },
                            512: {
                                slidesPerView: 2,
                                slidesPerGroup: 1,
                                centeredSlides : false,
                            },
                            993 : {
                                slidesPerView: 5,
                                slidesPerGroup: 1,
                            },
                        }" 
                        class="mySwiper"
                          >
                          <!-- <swiper-slide>
                                <div class="slide_box">
                                    <NuxtLink :to="localePath({ name:'cyo-setting-list-ring' })">
                                        <div class="img_box radious_eight">
                                            <picture>
                                                <source width="1352" height="1352" type="image/webp" srcset="~/assets/valentines/shop_by/engagement.webp">
                                                <img loading="lazy" fluid width="1352" height="1352" type="image/jpeg" src="~/assets/valentines/shop_by/engagement.jpg" alt="Shop by Engagement Rings Category">
                                            </picture>
                                        </div>
                                        <span>Engagement Rings</span>
                                    </NuxtLink>
                                </div>
                            </swiper-slide> -->
                            <swiper-slide>
                                <div class="slide_box">
                                    <NuxtLink :to="localePath({ name: 'shop-earring'})">
                                        <div class="img_box radious_eight">
                                            <picture>
                                                <source width="1352" height="1352" type="image/webp" srcset="~/assets/valentines/shop_by/stud.webp">
                                                <img loading="lazy" fluid width="1352" height="1352" type="image/jpeg" src="~/assets/valentines/shop_by/stud.jpg" alt="Shop by Engagement Rings Category">
                                            </picture>
                                        </div>
                                        <span>Stud Earrings</span>
                                    </NuxtLink>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="slide_box">
                                    <NuxtLink :to="localePath({ name: 'shop-eternity'})">
                                        <div class="img_box radious_eight">
                                            <picture>
                                                <source width="1352" height="1352" type="image/webp" srcset="~/assets/valentines/shop_by/eternity.webp">
                                                <img loading="lazy" type="image/jpeg" fluid width="1352" height="1352" src="~/assets/valentines/shop_by/eternity.jpg" alt="Shop by Eternity Rings Category">
                                            </picture>
                                        </div>
                                        <span>Eternity Rings</span>
                                    </NuxtLink>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="slide_box">
                                    <NuxtLink  :to="localePath({ name: 'shop-bracelet'})">
                                        <div class="img_box radious_eight">
                                            <picture>
                                            <source width="1352" height="1352" type="image/webp" srcset="~/assets/valentines/shop_by/bracelet.webp">
                                            <img loading="lazy" type="image/jpeg" fluid width="1352" height="1352" src="~/assets/valentines/shop_by/bracelet.jpg" alt="Shop by Diamond Pendants Category">
                                            </picture> 
                                        </div>
                                        <span>Tennis Bracelets </span>   
                                    </NuxtLink>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="slide_box">
                                    <NuxtLink :to="localePath({ name: 'shop-solitaire-necklace'})">
                                        <div class="img_box radious_eight">
                                            <picture>
                                            <source width="1352" height="1352" type="image/webp" srcset="~/assets/valentines/shop_by/pendant.webp">
                                            <img loading="lazy" type="image/jpeg" fluid width="1352" height="1352" src="~/assets/valentines/shop_by/pendant.jpg" alt="Shop by Tennis Bracelets Category">
                                            </picture>
                                        </div>
                                        <!-- <span>Diamond Pendants</span> -->
                                        <span>Solitaire Necklaces</span>
                                    </NuxtLink>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="slide_box">
                                    <NuxtLink :to="localePath({ name: 'shop-hoop'})">
                                        <div class="img_box radious_eight">
                                            <picture>
                                            <source width="1352" height="1352" type="image/webp" srcset="~/assets/valentines/shop_by/hoop.webp">
                                            <img loading="lazy" type="image/jpeg" fluid width="1352" height="1352" src="~/assets/valentines/shop_by/hoop.jpg" alt="Shop by Hoop Earrings Category">
                                            </picture>
                                        </div>
                                        <span>Hoop Earrings</span>
                                    </NuxtLink>
                                </div>
                            </swiper-slide>

                            <!-- <swiper-slide>
                                <div class="slide_box">
                                    <NuxtLink :to="localePath({ name:'cyo-setting-list-ring'})">
                                        <div class="img_box">
                                            <picture>
                                                <source width="761" height="761" type="image/webp" srcset="~/assets/home/category/ring.webp">
                                                <img loading="lazy" fluid width="761" height="761" type="image/jpeg" src="~/assets/home/category/ring.jpg" alt="Shop by Engagement Rings Category">
                                            </picture>
                                        </div>
                                        <span>Engagement Rings</span>
                                    </NuxtLink>
                                </div>
                            </swiper-slide> -->
                        </swiper>
                    </div>

                    <!-- <div class="container" v-else>
                        <div class="row for_desktop">
                            <div class="col-md-3" v-for="index in 4" :key="index">
                                <div class="prod_block">
                                    <div class="prod_image skeleton_lg">
                                        <img width="200" loading="lazy" height="200" alt="loader" src="~/assets/assets/loader.svg" />
                                    </div>
                                    <div class="prod_image skeleton_md">
                                        <img width="200" loading="lazy" height="200" alt="loader" src="~/assets/assets/loader.svg" />
                                    </div>
                                    <img width="200" loading="lazy" height="200" alt="loader" src="~/assets/assets/loader.svg" />
                                </div>
                            </div>
                        </div>
                        <div class="for_mobile text-center">
                            <div class="prod_block">
                                <div class="prod_image">
                                    <img width="200" loading="lazy" height="200" alt="loader" src="~/assets/assets/loader.svg" />
                                </div>
                            </div>
                        </div>
                    </div> -->
              
            </div>
                  
    </section>
</template>

<script setup>

const localePath = useLocalePath()
const props = defineProps({
  locale: String,
  page: String
})
const skeleton = ref(true);

const showNext = () => {
    $refs.c1.prev();
};

const showPrev = () => {
    $refs.c1.next();
};

onMounted(() => {
    nextTick(() => skeleton.value = false);
    // skeleton.value = false;
});
</script>

<style scoped>
section.shop_category {
    padding: 0 4%;
}
.transform-enter-active,
.transform-leave-active {
    transition: transform 0.5s linear;
    transform: translateY(-20px);
}

.transform-enter,
.transform-leave-to {
    transform: translateY(0px);

}

.slide_box{
    text-align: center;
    padding: 2%;
}

.step_skeleton {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.skeleton {
    width: 300px;
    height: 360px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.3);
    mask-image: radial-gradient(white, black);
    cursor: wait;
    overflow: hidden;
}

h4{
    font-size: 30px;
}
p{
    font-weight: 500;
    color: #000;
}
a{
    display: inline-block;
}
span{
    color: #000;
    display: block;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 2px;
    padding-top: 5%;
    text-align: center;
    text-transform: uppercase;
}

@media(max-width: 1400px){
   
    span{
        font-size: 16px;
        letter-spacing: 2px;
    }
    .step_skeleton{
        justify-content: space-around;
    }
    .skeleton {
        width: 180px;
        height: 230px;
    }
 
}
@media(max-width: 992px){
    span{
        font-size: 14px;
    }
    .step_skeleton{
        gap: 10px;
        justify-content: space-around;
    }
    .skeleton {
        width: 300px;
        height: 300px;
    }
    .step_skeleton .skeleton:nth-child(n+3) {
        display: none;
  }
}
@media(max-width: 767px){
    section.shop_category{
        padding: 0;
    }
    h4{
        font-size: 24px;
    }
    p{
        font-size: 16px;
        padding-left: 5%;
        padding-right: 5%;
    }
    .step_skeleton{
        gap: 8px;
    }
    .skeleton {
        width: 250px;
        height: 300px;
    }
    .step_skeleton .skeleton:nth-child(n+3) {
        display: none;
  }
}

@media (max-width: 500px){
    img{
        transition: none;
    }
    .step_skeleton{
        justify-content: center;
    }
    .skeleton {
        width: 180px;
        height: 200px;
    }
    .step_skeleton .skeleton:nth-child(n+3) {
        display: none;
  }
  }

/* --------animation---------- */
.img_box{
    overflow: hidden;
    max-width: 100%;
    max-height: 100%;
}
/* img{
    transition: 0.8s; 
    image-rendering: pixelated;
}
.img_box img:hover{
    transform: scale(1.2);
    transition: 0.8s;
} */
.slide_box:hover span{
    color: var(--green);
}
/* --------animation---------- */
</style>