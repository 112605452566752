<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6 cols-12 mb-4 mb-md-0">
                <div class="hues_block">
                    <picture>
                        <source media="(min-width: 768px)" width="1738" height="1108" type="image/webp" srcset="~/assets/spring/hues.webp">
                        <source media="(max-width: 767px)" width="1372" height="1780" type="image/webp" srcset="~/assets/spring/hues_mob.jpg">
                        <img loading="lazy" fluid width="1738" height="1108" type="image/jpeg" src="~/assets/spring/hues.jpg" alt="Hues Rings">
                    </picture>
                    <div class="upper_text">
                        <img class="hues_logo" src="~/assets/spring/hues.svg" height="152" width="217" alt="">
                        <p class="my-2">Colored Diamond Engagement Rings</p>
                        <p class="mb-3">Every hue from dawn to dusk - captured in diamonds.</p>
                        <router-link :to="localePath({ path: '/high-jewelry/hues-rings'})">Discover the Hues</router-link>
                    </div>
                </div>
            </div>
            <div class="col-md-6 cols-12">
                <div class="hues_block">
                    <picture>
                        <source media="(min-width: 768px)" width="1738" height="1108" type="image/webp" srcset="~/assets/spring/loveletters.webp">
                        <source media="(max-width: 767px)" width="1372" height="1780" type="image/webp" srcset="~/assets/spring/love_mob.jpg">
                        <img loading="lazy" fluid width="1738" height="1108" type="image/jpeg" src="~/assets/spring/loveletters.jpg" alt="Love Letters">
                    </picture>
                    <div class="upper_text upper_text_mod">
                        <p class="mb-2 uppercase">Introducing</p>
                        <h3 class="font-active">Love Letters</h3>
                        <p>Personalized brilliance for our <br> deepest sentiments.</p>
                        <h4>Coming soon...</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
 const localePath = useLocalePath()
</script>

<style scoped>
.hues_block{
    position: relative;
}
.hues_block img{
    border-radius: 8px;
}
.upper_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(0, -50%);
}

.upper_text p {
    font-size: 14px;
    color: #eaeaea;
}

.upper_text a {
    border: 1.5px solid #fff;
    border-radius: 4px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 180px;
    cursor: pointer;
    color: #fff;
}

.uppercase{
    text-transform: uppercase;
    letter-spacing: 2px;
}
.upper_text h3 {
    font-size: 32px;
    color: #fff;
}

.upper_text h4 {
    color: #eaeaea;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
}
.upper_text.upper_text_mod {
    left: 63%;
}

@media(max-width: 767px){
    .upper_text {
        transform: translate(-50%, -20%);
        text-align: center;
        width: 85%;
    }
    .upper_text a{
        margin: 0 auto;
        max-width: 100%;
    }
    img.hues_logo {
        width: 50%;
    }
    .upper_text.upper_text_mod {
        left: 50%;
        transform: translate(-50%, 20%);
    }
}
</style>