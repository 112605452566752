<template>
    <div class="preset_main pt-2 pb-4">
        <div class="title mb-4">
            <h4 class="font-active">Shop Engagement Rings</h4>
            <p>Tailored or Traditional: Two Roads to Your Ring Perfection</p>
        </div>
        
        <div class="row">
            <div class="col-lg-4 col-md-5 main_picture for_desktop">
                <picture>
                    <source width="1000" height="1216" type="image/webp" srcset="~/assets/home/preset_banner/banner.webp">
                    <source width="1000" height="1216" type="image/jpeg" srcset="~/assets/home/preset_banner/banner.jpg">
                    <img class="radious_eight" width="1000" loading="lazy" height="1216" src="~/assets/home/preset_banner/banner.jpg" type="image/jpeg" alt="preset banner"> 
                </picture>
            </div>
            <div class="col-lg-8 col-md-7 col-12 mx-auto padding_mod">
                <div class="bg_gradiant radious_eight">
                    <div class="row cyo_block mb-3">
                        <div class="col-lg-4 col-md-4 col-5 text-center">
                            <img width="230" loading="lazy" height="230" src="~/assets/home/preset_banner/cyo.png" type="image/png" alt="home banner">
                        </div>
                        <div class="col-lg-8 col-md-8 col-7">
                            <div class="image_content">
                                <h3 class="font-active">Create Your Own</h3>
                                <h4>ENGAGEMENT RINGS</h4>
                                <p class="for_desktop">Express your unique love story with a custom symbol created just for you!</p>
                                <router-link :to="localePath({ name:'cyo-setting-list-ring'})" class="radious_four btn-p-animation">
                                    <span>SHOP NOW</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg_gradiant bg_gradiant_mod radious_eight">
                    <div class="row cyo_block">
                        <div class="col-lg-8 col-md-8 col-7">
                            <div class="image_content">
                                <h3 class="font-active">Pre-Designed</h3>
                                <h4>ENGAGEMENT RINGS</h4>
                                <p class="for_desktop">Discover your ideal match where traditional craftsmanship meets modern innovation!</p>
                                <router-link :to="localePath({ name:'preset-listing'})" class="radious_four btn-p-animation">
                                    <span>SHOP NOW</span>
                                </router-link>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-5 text-center">
                            <img width="230" loading="lazy" height="230" src="~/assets/home/preset_banner/preset.png" type="image/png" alt="home banner">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
const localePath = useLocalePath()

</script>

<style scoped>
/* Changes padding of cyo_block from  padding: 2.2% 1%; to 2.4% */
.title{
    text-align: center;
}
.title h4{
    font-size: 30px;
    color: #212529;
}
.title p{
    font-weight: 500;
    color: #606060;
}
.bg_gradiant{
    background-image: linear-gradient(to right, #fff2e0, #f5f1eb); 
}
.bg_gradiant_mod{
    padding-left: 5%;
}
.image_content{
    /* padding-left: 4%; */
}
.image_content h3{
    font-size: 28px;
    color: #405955;
}
.image_content h4 {
    font-size: 16px;
    letter-spacing: 3px;
    color: #606060; 
}
.image_content p {
    font-size: 16px;
    color: #606060;
    padding-right: 3%;
    font-weight: 500;
    margin: 3% 0;
}
.image_content a {
    color: #ffffff;
    display: inline-block;
    border: 1px solid #405955;
    font-weight: 600;
    margin: 0 auto;
    padding: 9px 25px;
    text-transform: uppercase;
    min-width: 200px;
    text-align: center;
    font-size: 14px;
    background: #405955;
}
.cyo_block{
   
    align-items: center;
    padding: 2.4% 1%;
}
@media(min-width: 768px){
    .padding_mod{
        padding-left: 0;
    }
}
@media(max-width: 1470px){
    .main_picture img{
        height: 100%;
        object-fit: cover;
    }
}
@media(max-width: 1100px){
    .cyo_block{
        padding: 2.5% 0;
    }
    .image_content h3 {
        font-size: 24px;
    }
    .image_content p{
        font-size: 14px;
    }
}
@media(max-width: 992px){
    .image_content a {
        padding: 7px 15px;
        min-width: 150px;
    }
    .cyo_block{
        padding: 6.5% 3%;
    }
    .image_content p{
        display: none;
    }
    .image_content h4 {
        margin-bottom: 15px;
    }
}
@media(max-width: 767px){
    .image_content {
        /* padding-left: 0; */
    }
    .preset_main.py-4 {
        padding-top: 0 !important;
    }
    .title h4{
        font-size: 24px;
    }
    .title p{
        font-size: 14px;
        padding-left: 5%;
        padding-right: 5%;
    }
    .image_content h3 {
        font-size: 30px;
    }
    .image_content h4{
        font-size: 15px;
    }
    .image_content a {
        color: #353535;
        display: inline-block;
        border: 0;
        font-weight: 600;
        margin: 0 auto;
        padding: 0;
        text-transform: uppercase;
        min-width: auto;
        text-align: center;
        font-size: 16px;
        border-bottom: 1px solid;
        background: none;
    }
}

@media(max-width: 600px) {
    .image_content h3 {
        font-size: 26px;
    }
    .image_content h4{
        font-size: 13px;
    }
}


@media(max-width: 512px) {
    
    .image_content h3 {
        font-size: 20px;
    }
    .image_content h4{
        font-size: 12px;
    }
    .image_content a {
        font-size: 14px;
        border-radius: 0;
    }
}
</style>
